import React from "react"
import PropTypes from "prop-types"

import Col from "react-bootstrap/Col"
import Btn from "../../../../custom/Button"

import bggroup from "./../../../../../images/svgs/breakoutgroup_img.png"

import Styles from "./style.module.scss"

// !definition of component
/**
 *
 * @param props --> heading, context, buttonText and onClick
 * @description --> make cards for AddDataModal
 * @returns AddDataCard component for AddDataModal
 */
// ! component

const AddDataCard = (props) => {
  return (
    <Col lg={12 / props.size} md={12 / props.size} sm={12 / props.size} xs={12 / props.size} className="float-left px-1">
      <div className={`px-2 pt-2 col-12 px-0 d-flex ${Styles.heading_holder}`}>
        <div className="col-6 float-left px-0 d-flex align-items-center">
          <h4 className="text-white fs-20px mb-0 pl-2 align-items-center font-weight-bold">{props.heading}</h4>
        </div>
        <div className="col-6 float-left pr-0">
          <img src={bggroup} alt="Group" className="mb-1" />
        </div>
      </div>

      <div className={`px-2 py-2 ${Styles.context_holder}`}>
        <p className="text-white fs-14px pl-2">{props.context}</p>
        <div onClick={props.onClick}>
          <Btn type="medium" className={`position-absolute ml-2 my-2 ${Styles.btn}`}>
            {props.buttonText}
          </Btn>
        </div>
      </div>
    </Col>
  )
}

AddDataCard.propTypes = {
  heading: PropTypes.string.isRequired,
  context: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default AddDataCard
