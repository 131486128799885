import React, { useContext, useEffect, useState } from "react"

import SEO from "../seo"
import { isBrowser } from "../../services/developer_mode"
import { getRequest } from "../../services/rest_service"

import Notification from "../custom/Notification"
import Navbar from "./dashboard-nav"
import ClassesData from "./dashboard-data/classes-data"
import CoursesData from "./dashboard-data/courses-data"
import DeleteModal from "../custom/DeleteModal"
import ParticipantsDataModel from "./participants-data-modal"
import UploadParticipantsModal from "../custom/UploadParticipantsModal"
import AddDataButton from "./add-data-button"
import AppIntegrationModal from "../app-integration"
import { GlobalContext } from "../../stores/global_store/GlobalStore"
import { SET_APP_DATA } from "../../stores/global_store/GlobalActions"
import ExternalAppIntegrationStatus from "../ExternalAppIntegrationStatus"
import IntegrationStatusStore from "../../stores/integration_status_store/IntegrationStatusStore"

const Dashboard = ({ props }) => {
  const location = props.location

  const [globalStore, dispatchGlobalStore] = useContext(GlobalContext)

  const [company, setCompany] = useState({})

  const [courses, setCourses] = useState([])
  const [coursesNext, setCoursesNext] = useState(null)
  const [courseType, setCourseType] = useState("")
  const [courseLoader, setCourseLoader] = useState(false)
  const [course, setCourse] = useState({})

  const [trainings, setTrainings] = useState([])
  const [trainingsNext, setTrainingsNext] = useState(null)
  const [trainingsLoader, setTrainingsLoader] = useState(false)
  const [trainingType, setTrainingType] = useState("")
  const [refetchTrainings, setRefetchTrainings] = useState(false)

  // const [stages, setStages] = useState({})
  const [training, setTraining] = useState({})
  const [participants, setParticipants] = useState([])

  const [showView, setShowView] = useState(false)

  const [toastData, setToastData] = useState(null)
  const [deleteModalData, setDeleteModalData] = useState({})
  const [showAddStudentsModal, setShowAddStudentsModal] = useState(false)

  const classesNavbarSections = [
    {
      name: "All Classes",
      onClick: () => {
        fetchTrainingType("")
      },
      active: trainingType === "" || trainingType === "all",
    },
    {
      name: "Upcoming",
      onClick: () => {
        fetchTrainingType("upcoming")
      },
      active: trainingType === "upcoming",
    },
    {
      name: "Completed",
      onClick: () => {
        fetchTrainingType("past")
      },
      active: trainingType === "past",
    },
  ]

  const coursesNavbarSections = [
    {
      name: "All Courses",
      onClick: () => {
        fetchCourseType("")
      },
      active: courseType === "" || courseType === "all",
    },
    {
      name: "Published",
      onClick: () => {
        fetchCourseType("published")
      },
      active: courseType === "published",
    },
    {
      name: "Drafts",
      onClick: () => {
        fetchCourseType("draft")
      },
      active: courseType === "draft",
    },
  ]

  useEffect(() => {
    fetchCompanies()
    if (sessionStorage && sessionStorage.getItem("openAppIntegrationModal")) {
      dispatchGlobalStore({ type: SET_APP_DATA, payload: { showModal: true } })
      sessionStorage.removeItem("openAppIntegrationModal")
    }
  }, [])

  // Course and training filters are working by changing hash in dashboard url.
  // This is to fetch courses or trainings once hash is updated
  useEffect(() => {
    if (location.hash) {
      let [location_hash, hash_type] = location.hash.replace("#", "").split("_")
      if (company.id) {
        if (hash_type === "classes") {
          fetchTrainings(company, location_hash === "completed" ? "past" : location_hash)
        } else if (hash_type === "courses") {
          fetchCourses(company, location_hash)
        }
      }
    }
  }, [location && window.location.hash])

  useEffect(() => {
    if (refetchTrainings) {
      fetchTrainings(company, trainingType ? trainingType : "all")
      setRefetchTrainings(false)
    }
  }, [refetchTrainings])

  useEffect(() => {
    if (isBrowser()) {
      let location_hash = window.location.hash.replace("#", "")
      if (location_hash === "") {
        setTrainingType("")
      } else if (location_hash === "completed") {
        setTrainingType("past")
      } else if (location_hash === "upcoming") {
        setTrainingType("upcoming")
      } else if (location_hash === "published") {
        setCourseType("published")
      } else if (location_hash === "draft") {
        setCourseType("draft")
      }
    }
  }, [isBrowser()])

  const fetchCompanies = async () => {
    const response = await getRequest(`/fetch-company/?id=${localStorage.getItem("company_id")}`)
    if (response.success) {
      let location_hash = window.location.hash.replace("#", "")
      localStorage.setItem("company_logo", response.data[0].avatar)
      setCompany(response.data[0])
      fetchCourses(response.data.length > 0 ? response.data[0] : [], location_hash === "published" ? "published" : location_hash === "draft" ? "draft" : "all")
      fetchTrainings(
        response.data.length > 0 ? response.data[0] : [],
        location_hash === "completed" ? "past" : location_hash === "upcoming" ? "upcoming" : "all"
      )
    }
  }

  const fetchTrainings = async (comp, type) => {
    setTrainingsLoader(false)
    const response = await getRequest(`/basic-trainings-list/?course__company=${comp.id}&type=${type}`)
    if (response.success) {
      setTrainings(response.data.results)
      setTrainingsNext(response.data.next)
      setTrainingsLoader(true)
    } else {
      setTrainings([])
      setTrainingsLoader(true)
    }
  }

  const fetchCourses = async (comp, type) => {
    setCourseLoader(false)
    let value = type === "all" ? "" : `&isdraft=${type === "draft" ? "true" : "false"}`
    const response = await getRequest(`/basic-courses-list/?company=${comp.id}` + value)
    if (response.success) {
      setCourses(response.data.results)
      setCoursesNext(response.data.next)
      setCourseLoader(true)
    } else {
      setCourseLoader(true)
    }
  }

  const fetchTrainingType = (type) => {
    if (type !== trainingType) {
      setTrainingType(type)
      window.history.replaceState(null, null, `${window.location.origin}${window.location.pathname}${type === "all" || type === "" ? "" : `#${type}`}`)
      fetchTrainings(company, type === "" ? "all" : type)
    }
  }

  const fetchCourseType = (type) => {
    if (type !== courseType) {
      setCourseType(type)
      window.history.replaceState(null, null, `${window.location.origin}${window.location.pathname}${type === "all" || type === "" ? "" : `#${type}`}`)
      fetchCourses(company, type === "" ? "all" : type)
    }
  }

  const handleShow = (card, type) => {
    if (type === "course") {
      setCourse(card)
      setTraining(card.elearning_training_detail)
    } else {
      setTraining(card)
    }
    setShowAddStudentsModal(true)
  }

  const handleShowView = async (training) => {
    setTraining(training)
    const response = await getRequest(`/trainings/${training.id}/students/`)
    if (response.success) {
      setParticipants(response.data)
      setShowView(true)
    }
  }

  const fetchStudents = async () => {
    const isCourse = Object.keys(course).length > 0
    const response = await getRequest(`/trainings/${training.id}/students/`)
    if (response.success) {
      let dummy = isCourse ? [...courses] : [...trainings]
      let index = dummy.findIndex((res) => (isCourse ? res.id === course.id : res.id === training.id))
      if (isCourse) {
        dummy[index].elearning_training_detail.participants = response.data.length
        setCourses(dummy)
      } else {
        dummy[index].participants = response.data.length
        setTrainings(dummy)
      }
      setParticipants(response.data)
      setShowView(true)
    }
  }

  const fetchTrainingHash = () => {
    let location_hash = window.location.hash.replace("#", "")
    fetchTrainingType(location_hash === "completed" ? "past" : location_hash === "upcoming" ? "upcoming" : "all")
  }
  const fetchCourseHash = () => {
    let location_hash = window.location.hash.replace("#", "")
    fetchCourseType(location_hash === "published" ? "published" : location_hash === "draft" ? "draft" : "all")
  }

  return (
    <div>
      <SEO title="Dashboard" />
      <div className={` pb-4 pt-2 px-3`}>
        <div id="fetch_training_btn" className="d-none" onClick={fetchTrainingHash} />
        <div id="fetch_course_btn" className="d-none" onClick={fetchCourseHash} />
        <div>
          <div>
            <Navbar heading="My Classes" sections={classesNavbarSections} />
            <IntegrationStatusStore>
              <ClassesData
                companyID={company.id}
                trainingsLoader={trainingsLoader}
                trainings={trainings}
                setTrainings={setTrainings}
                setRefetchTrainings={setRefetchTrainings}
                trainingsNext={trainingsNext}
                setTrainingsNext={setTrainingsNext}
                setTraining={setTraining}
                setDeleteModalData={setDeleteModalData}
                setToastData={setToastData}
                handleShow={handleShow}
                handleShowView={handleShowView}
              />
              <ExternalAppIntegrationStatus />
            </IntegrationStatusStore>
          </div>
          <div className="mt-5">
            <Navbar heading="My Courses" sections={coursesNavbarSections} />
            <CoursesData
              companyID={company.id}
              courseLoader={courseLoader}
              courses={courses}
              setCourses={setCourses}
              coursesNext={coursesNext}
              setCoursesNext={setCoursesNext}
              setDeleteModalData={setDeleteModalData}
              setToastData={setToastData}
              handleShow={handleShow}
              handleShowView={handleShowView}
            />
          </div>
        </div>
        <AddDataButton companyID={company.id} coursesExist={courses && courses.length > 0} setToastData={setToastData} />
      </div>
      <Notification data={toastData} setData={setToastData} />

      <AppIntegrationModal />

      <UploadParticipantsModal
        show={showAddStudentsModal}
        setShow={(arg) => {
          setShowAddStudentsModal(arg)
          setCourse({})
        }}
        training_id={training.id}
        refetchParticipants={() => {
          fetchStudents("refetch")
        }}
      />
      <ParticipantsDataModel showView={showView} setShowView={setShowView} participants={participants} />
      <DeleteModal
        show={Object.keys(deleteModalData).length > 0}
        onHide={() => {
          setDeleteModalData({})
        }}
        itemName={deleteModalData?.itemName}
        action={deleteModalData?.action}
      />
    </div>
  )
}

export default Dashboard
