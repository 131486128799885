import React, { Component } from "react"
import { navigate, Link } from "gatsby"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Form from "react-bootstrap/Form"
import Notification from "./custom/Notification"
import Image from "./custom/Image"
import LoginInputField from "./custom/Login-InputField"
import SEO from "../components/seo"
import authClient from "../services/auth"

import turientimg from "../images/turientimg.png"
import turientLogo from "../images/favicon.png"
import LoginCSS from "../modularscss/Login.module.scss"

class Login extends Component {
  state = {
    email: "",
    password: "",
    error_message: "",
    show_error: "d-none",
    login_btn_disable: false,
    width: 0,
    show_password: false,
    toast_data: null,
  }

  componentDidMount() {
    if (localStorage.getItem("successMsg")) {
      this.setToastData({
        type: "success",
        title: "Successful! You are just one step away from awesomeness! Confirmation link has been sent to your email, Please confirm your account.",
      })
    }
    if (this.props.location.state != null) {
      if (this.props.location.state.email != undefined || this.props.location.state.email != null) {
        this.setState({ email: this.props.location.state.email })
      } else {
        if (localStorage.getItem("username") != null) {
          this.setState({ email: localStorage.getItem("username") })
        }
      }
    }
    if (authClient.isAuthenticated()) {
      if (localStorage.getItem("reset_first_time_password") == true) {
        navigate("/app/password-reset")
      } else {
        if ([false, "false"].includes(localStorage.getItem("school_exists"))) {
          navigate("/app/signup-success")
        } else {
          if (localStorage.getItem("school_setup")) {
            navigate("/app/school-setup")
          } else {
            navigate("/app/dashboard")
          }
        }
      }
    }
    if (!authClient.isAuthenticated()) {
      if (localStorage.getItem("student_authenticity_token")) {
        if (localStorage.getItem("student_authenticity_token")?.length !== 0) {
          window.location.reload()
        }
      }
    }
    this.setState({ width: window?.innerWidth })
    window.addEventListener("resize", this.handleWindowSizeChange)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange)
  }
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth })
  }

  handleLogin = (event) => {
    event.preventDefault()
    if (this.state.email !== "") {
      if (this.state.password !== "") {
        let loginMsg = authClient.GetKey({ username: this.state.email.toLowerCase(), password: this.state.password, location: this.props.location })
        loginMsg.then((res) => {
          this.setState({ toast_data: res })
        })
        this.setState({ login_btn_disable: true })
        setTimeout(() => {
          this.setState({ login_btn_disable: false })
        }, 2000)
      } else {
        this.setState({ toast_data: { type: "error", title: "Please provide password" } })
      }
    } else {
      this.setState({ toast_data: { type: "error", title: "Please provide email id" } })
    }
  }
  handleChange = (event) => {
    if (event.target.name === "email") {
      localStorage.setItem("username", event.target.value)
    }
    if (event.target) {
      this.setState({ [event.target.name]: event.target.value })
    }
    if (event.target.name === "password" || event.target.name === "email") {
      // this.setState({error_message: ''})
    }
  }

  displayTitle = () => {
    return (
      <div className="m-0 pt-0 pb-9px mt-0 d-inline-flex align-items-center">
        <Image alt="Turient logo" src={turientLogo} className="d-inline-block align-center w-50px h-50px" />{" "}
        <Link to="/" className="ml-2 turient-body-text-color fs-32px text-decoration-none font-weight-bold">
          {"Turient"}
        </Link>
      </div>
    )
  }

  toggleShowPassword = () => {
    this.setState({ show_password: !this.state.show_password })
  }

  setToastData = (val) => {
    this.setState({ toast_data: val })
  }

  render() {
    return (
      <div>
        <SEO title="Turient | LogIn" />
        <Row className="justify-content-md-center mx-auto w-100 pb-4 mt-4">
          {this.state.width > 980 && (
            <Col lg="3" md="4" sm="4" className="float-left mr-0 bg-121B2B border border-1 border-121B2B pl-20px">
              <Row className="text-center border-bottom-E0E2E5 ">{this.displayTitle()}</Row>
              <p className="poppins_font text_font_semibold turient-body-text-color fs-36px pt-18px mb-10px bold-500 ">
                Welcome to
                <br /> your Online School
              </p>
              <p className="poppins_font fs-21px">You are just a step away!</p>
              <Row>
                <Image src={turientimg} alt="turientmg" />
              </Row>
            </Col>
          )}
          <Col lg="4" md="6" sm="6" className={`pt-2 pb-2 ${this.state.width > 980 ? "pl-35" : ""} d-flex bg-212C42`}>
            <Form onSubmit={this.handleLogin} className={`${this.state.width > 980 ? "w-98" : "w-100"}`}>
              {this.state.width < 980 && <div className="signup-display-title">{this.displayTitle()}</div>}
              <h2 className="poppins_font w-100 pl-2 mt-4 mb-1 pb-2 fs-24px bold-600 color-c6cff4">Login</h2>
              <Col className="mt-10px">
                <LoginInputField
                  labelClass={`poppins_font fs-18px color-c6cff4`}
                  labelText={"Your Email"}
                  onChange={this.handleChange}
                  name="email"
                  value={this.state.email}
                  placeholder={`Enter your email`}
                  inputClass={`autofill_303C54 fs-18px bg-303C54`}
                  type="email"
                  showHideInput={false}
                  showErrorMsg={false}
                />
              </Col>
              <Col className="mt-4">
                <LoginInputField
                  labelClass={`poppins_font fs-18px color-c6cff4`}
                  labelText={"Password"}
                  onChange={this.handleChange}
                  name="password"
                  value={this.state.password}
                  placeholder={`Enter your Password`}
                  inputClass={`autofill_303C54 font-500 text-E0E2E5 bg-303C54 border-inherit`}
                  type={this.state.show_password ? "text" : "password"}
                  showHideInput={true}
                  showHideInputValue={this.state.show_password}
                  setShowHideInputValue={() => this.toggleShowPassword()}
                  showErrorMsg={false}
                  minLength={8}
                />
              </Col>
              <Col sm="12" md="12" lg="12" className="d-flex mt-2">
                <p className="fs-12px poppins_font mt-1 border-bottom-no-color">
                  <Link className="color-c6cff4 underline" to="/app/forgot-password" state={{ email: this.state.email }}>
                    Login using OTP
                  </Link>
                </p>
                <p className="poppins_font mt-1 ml-auto fs-12px border-bottom-no-color">
                  <Link className="color-c6cff4 underline" to="/app/reset-password-otp" state={{ email: this.state.email }}>
                    Forgot password
                  </Link>
                </p>
              </Col>

              <Col className="mt-2 d-flex justify-content-center">
                <button type="submit" size="sm" className={`border_on_hover fs-15px ${LoginCSS.login_button}`}>
                  <div className={`m-0 poppins_font px-4 d-flex justify-content-between`}>
                    <div>Log in</div>
                  </div>
                </button>
              </Col>
              <div className="text-center mt-160px">
                <p className="m-0 poppins_font fs-16px color-c6cff4">Don't have an account?</p>
                <div className="poppins_font fs-16px">
                  <Link to="/app/signup" state={{ email: this.state.email }} className="border-bottom-no-color text-white">
                    Create your account for free
                  </Link>
                </div>
              </div>
            </Form>
          </Col>
        </Row>
        <Notification data={this.state.toast_data} setData={this.setToastData} />
      </div>
    )
  }
}

export default Login
