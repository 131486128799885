import React, { useState } from "react"
import PropTypes from "prop-types"

import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"

import AddDataModal from "./add-data-modal"

import Styles from "./style.module.scss"

// !definition of component
/**
 *
 * @param props --> companyID, coursesExist and setToastData
 * @description --> Button to toggle add Data modal
 * @returns AddDataButton component for dashboard
 */
// ! component

const AddDataButton = (props) => {
  const [showAddDataModal, setShowAddDataModal] = useState(false)

  return (
    <>
      <OverlayTrigger
        placement="top"
        delay={{ show: 150, hide: 200 }}
        overlay={showAddDataModal === true ? <Tooltip></Tooltip> : <Tooltip>Add new course/classes/resource/poll</Tooltip>}
      >
        <div className={`${Styles.button_holder}`} onClick={() => setShowAddDataModal(!showAddDataModal)}>
          <div className={`pointer text-center bg-white ${Styles.button}`}>{showAddDataModal === true ? <div>x</div> : <div className="">+</div>}</div>
        </div>
      </OverlayTrigger>
      <AddDataModal
        showAddDataModal={showAddDataModal}
        setShowAddDataModal={setShowAddDataModal}
        companyID={props.companyID}
        coursesExist={props.coursesExist}
        setToastData={props.setToastData}
      />
    </>
  )
}

AddDataButton.propTypes = {
  companyID: PropTypes.any,
  coursesExist: PropTypes.bool.isRequired,
  setToastData: PropTypes.func.isRequired,
}

export default AddDataButton
