import React from "react"
import PropTypes from "prop-types"

import Col from "react-bootstrap/Col"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"

import Styles from "./style.module.scss"

// !definition of component
/**
 *
 * @param props --> label and onClick
 * @description --> gives a button to add data if no data is present
 * @returns NoData component for dashboard
 */
// ! component

const NoData = (props) => {
  return (
    <div className={`p-3 text-white align-self-center ${Styles.no_data}`}>
      <div onClick={props.onClick} className={`p-4  text-center float-left create-button-hover pointer ${Styles.add_button}`}>
        <FontAwesomeIcon icon={faPlus} size="lg" />
        <p className={`mt-2 mb-0`}>{props.label}</p>
      </div>
    </div>
  )
}

NoData.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default NoData
