import React from "react"
import PropTypes from "prop-types"
import Image from "../Image"
import LoginFormError from "../../LoginFormError"

import hidePasswordImg from "../../../images/svgs/eye-hide.svg"
import showPasswordImg from "../../../images/svgs/eye-show.svg"

const LoginInputField = (props) => {
  let inputProps = {}
  if (props?.maxLength) inputProps["maxLength"] = props?.maxLength
  if (props?.minLength) inputProps["minLength"] = props?.minLength

  return (
    <div style={props?.style} className={`${props?.className}`}>
      <div className={`mb-2 d-flex justify-content-between ${props?.labelClass} color-c6cff4`}>
        <div>{props?.labelText}</div>
      </div>
      <div className={`d-inline-flex w-100 align-items-center p-0 position-relative registration-input`}>
        <input
          onChange={(e) => {
            if (props?.onChange) props.onChange(e)
          }}
          onKeyPress={(e) => {
            if (props?.onKeyPress) props.onKeyPress(e)
          }}
          id={props?.id ?? props?.name}
          name={props?.name}
          autoComplete="off"
          value={props?.value}
          placeholder={props?.placeholder}
          className={`form-control registration-input ${props?.inputClass}`}
          type={props?.type}
          {...inputProps}
        />
        {props?.showHideInput && (
          <Image
            src={props?.showHideInputValue ? showPasswordImg : hidePasswordImg}
            className="w-22px h-22px mr-1 position-absolute right-5px"
            onClick={(e) => {
              if (props?.setShowHideInputValue) props.setShowHideInputValue()
            }}
          />
        )}
      </div>
      {props?.showErrorMsg && <LoginFormError errorText={props?.errorMsg} />}
    </div>
  )
}

LoginInputField.propTypes = {
  id: PropTypes.string, //Id of the input
  name: PropTypes.string.isRequired, //name of the input
  value: PropTypes.string, //value of the input
  placeholder: PropTypes.string, //placeholder of the input
  inputClass: PropTypes.string, //Class Names of the input
  showHideInput: PropTypes.bool, //Whether to display the eye for show/hide text
  showHideInputValue: PropTypes.bool, //Value controlling the show/hide text
  maxLength: PropTypes.number, //max length of the input
  minLength: PropTypes.number, //min length of the input
  onChange: PropTypes.func, //Onchange function
  onKeyPress: PropTypes.func, //onKeyPress function
  setShowHideInputValue: PropTypes.func, //function controlling show/hide text
  showErrorMsg: PropTypes.bool, //Whether to show error Message below the input
  errorMsg: PropTypes.string, //Error Message to be displayed
  type: PropTypes.string.isRequired, //Type is one of text/password/email
  style: PropTypes.object, //Style for the Container
  className: PropTypes.string, //Class for the Container
  labelClass: PropTypes.string, //Label class name
  labelText: PropTypes.string, //Label Text
}

LoginInputField.defaultProps = {
  type: "text",
  style: {},
  className: "",
  labelClass: "",
  inputClass: "",
  placeholder: "",
  showHideInput: false,
  errorMsg: "",
  showErrorMsg: false,
}
export default LoginInputField
