import React, { useState } from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"

import { getRequest } from "../../../../services/rest_service"

import SharedArtifact from "../../../shared-artifact"
import ShowMoreButton from "../show-more-button"
import CourseCard from "./course-card"
import NoData from "../no-data"
import { HasCreatePermission } from "../../../../utils/PermissionDataAccess"
import { COURSES } from "../../../../constants/permissionsConstant"

// !definition of component
/**
 *
 * @param props --> companyID, courseLoader, courses, coursesNext, setDeleteModalData, setToastData, handleShow and handleShowView
 * @description --> renders the courses data on dashboard
 * @returns CoursesData component for dashboard
 */
// ! component

const CoursesData = (props) => {
  const [showSharedArtifact, setShowSharedArtifact] = useState(false)
  const [sharedArtifactProps, setSharedArtifactProps] = useState({})
  const [showMoreLoader, setShowMoreLoader] = useState(false)
  const fetchNextCourses = async (next_url) => {
    setShowMoreLoader(true)
    const response = await getRequest(next_url)
    if (response.success) {
      if (response.data.results.length > 0) {
        props.setCourses([...props.courses, ...response.data.results])
      }
      props.setCoursesNext(response.data.next)
      setShowMoreLoader(false)
    }
  }
  return (
    <>
      {props.courseLoader === true ? (
        props.courses ? (
          <React.Fragment>
            <div className={`d-flex flex-wrap pr-0 pl-0 mt-3`}>
              {props.courses.map((datum, index) => (
                <CourseCard
                  key={index}
                  datum={datum}
                  courses={props.courses}
                  setCourses={props.setCourses}
                  companyID={props.companyID}
                  setDeleteModalData={props.setDeleteModalData}
                  setToastData={props.setToastData}
                  setShowSharedArtifact={setShowSharedArtifact}
                  setSharedArtifactProps={setSharedArtifactProps}
                  handleShow={props.handleShow}
                  handleShowView={props.handleShowView}
                />
              ))}
              {HasCreatePermission(COURSES) && props.courses?.length <= 7 && (
                <NoData
                  label="Create Course"
                  onClick={() => navigate(`/app/companies/${props.companyID}/courses/${props.courses.length > 0 ? "course-information" : "create"}`)}
                />
              )}
            </div>
            <ShowMoreButton
              visible={props.coursesNext !== null}
              loading={showMoreLoader}
              onClick={(e) => {
                e.preventDefault()
                fetchNextCourses(props.coursesNext)
              }}
            />
          </React.Fragment>
        ) : (
          <div className="text-center">No Courses available</div>
        )
      ) : (
        <p className="animated fadeIn pt-3 text-center">Loading...</p>
      )}
      <SharedArtifact show={showSharedArtifact} hide={() => setShowSharedArtifact(false)} {...sharedArtifactProps} />
    </>
  )
}

CoursesData.propTypes = {
  companyID: PropTypes.any,
  courseLoader: PropTypes.bool.isRequired,
  courses: PropTypes.array.isRequired,
  setCourses: PropTypes.func.isRequired,
  coursesNext: PropTypes.any,
  setCoursesNext: PropTypes.func.isRequired,
  setDeleteModalData: PropTypes.func.isRequired,
  setToastData: PropTypes.func.isRequired,
  handleShow: PropTypes.func.isRequired,
  handleShowView: PropTypes.func.isRequired,
}

export default CoursesData
