import React, { useContext, useEffect, useState } from "react"
import { navigate } from "@reach/router"

import { Modal } from "react-bootstrap"
import Style from "./style.module.scss"
import Btn from "../custom/Button"
import CancelWhite from "../../images/svgs/close.svg"
import muralIcon from "../../images/svgs/mural-icon.svg"
import Calendar from "../../images/svgs/calendar.svg"

import WarningIcon from "../../images/svgs/warning_icon.svg"
import SuccessTick from "../../images/svgs/sucesstick.svg"

import Image from "../custom/Image"
import { IntegrationStatusContext } from "../../stores/integration_status_store/IntegrationStatusStore"
import utcDateTime from "../../services/utcdatetime"
import { ScholarHatLogo } from "../../constants/global"
import CopyToClipBoardWithIcon from "../common/CopyToClipBoard"
import { RESET_TRAINING_INTEGRATION_STATUS_MODAL } from "../../stores/integration_status_store/IntegrationStatusActions"
import { getRequest, putRequest } from "../../services/rest_service"
import { UserSocketContext } from "../../stores/socket/user_socket/userSocketProvider"
import { GlobalContext } from "../../stores/global_store/GlobalStore"
import { SET_APP_DATA } from "../../stores/global_store/GlobalActions"
import LocalStorage from "../../utils/LocalStorageAccess"
import { COMPANY } from "../../constants/LocalStorageConstant"

const ExternalAppIntegrationStatus = () => {
  const [userWSready, userWSval, userWSsend] = useContext(UserSocketContext)
  const [globalStore, disptachGlobalStore] = useContext(GlobalContext)

  const [state, dispatch] = useContext(IntegrationStatusContext)
  const [integrationStatusData, setIntegrationStatusData] = useState([])
  const [showUpdatingButton, setShowUpdatingButton] = useState(false)
  const [totalFailed, setTotalFailed] = useState(0)

  useEffect(() => {
    if (userWSval?.action === "third_party_app_sync" && userWSval?.training === state.data.id) {
      getIntegrationStatusData()
    }
  }, [userWSval])

  useEffect(() => {
    if (state.status) {
      getIntegrationStatusData()
    }
  }, [state.status])

  const getIntegrationStatusData = async () => {
    let res = await getRequest(`/training/integration-status/${state.data.id}/`)
    if (res.success) {
      setIntegrationStatusData(res.data.data)
      setTotalFailed(res.data.total_failed)
      setShowUpdatingButton(false)
    }
  }

  const retryThirdParty = async () => {
    let res = await putRequest(`/retry-third-party-app/${state.data.id}/`)
    if (res.success) {
      setShowUpdatingButton(true)
      setTotalFailed(0)
      setIntegrationStatusData([])
    }
  }

  return (
    <Modal size="lg" show={state.status} className="modal-content-bg-transparent white-text">
      <Modal.Body className="bg-212C42 d-flex br-4px">
        <div className={`${Style.integration_status_left_section}`}>
          <Image src={state.data.avatar ? state.data.avatar : ScholarHatLogo} alt={`Cancel`} className={`pointer`} height={"40px"} width={"40px"} />
          <div className="fs-14px bold-600">{state.data.name}</div>
          <div className="d-flex mt-2">
            <Image src={Calendar} height={"30px"} width={"30px"} />
            <div className="fs-10px">
              <div>{utcDateTime.displayTrainingDate(state.data.start_date, state.data.end_date)}</div>
              <div>{utcDateTime.displayTimeDetail(state.data.start_date, state.data.end_date)}</div>
            </div>
          </div>
        </div>
        <div className={`${Style.integration_status_vertical_seperator}`}></div>
        <div className={`${Style.integration_status_right_section}`}>
          <Image
            onClick={(e) => {
              e.preventDefault()
              dispatch({ type: RESET_TRAINING_INTEGRATION_STATUS_MODAL })
            }}
            src={CancelWhite}
            alt={`Cancel`}
            className={`pointer ${Style.close_btn}`}
          />
          {totalFailed > 0 ? (
            <div className="text-center">
              <div className="fs-11px">{totalFailed} links failed to update</div>
              {totalFailed > 0 && (
                <Btn
                  className={`px-4 my-1 py-1 bg-313C54 border-white ${Style.updating_button}`}
                  onClick={() => {
                    retryThirdParty()
                  }}
                >
                  Try Again
                </Btn>
              )}
              {totalFailed > 0 && (
                <div className="fs-9px">Sometime updates fail due to error in 3rd party app sync. Please update again to resolve the issue.</div>
              )}
            </div>
          ) : showUpdatingButton ? (
            <div className="text-center">
              <Btn className={`px-4 my-1 py-1 ${Style.success_button} disable-content`}>Updating</Btn>
            </div>
          ) : (
            <div className="text-center">
              <Btn className={`px-4 my-1 py-1 ${Style.success_button} disable-content`}>Third Party App Successfully Sync</Btn>
            </div>
          )}
          {showUpdatingButton && (
            <div className="d-flex align-items-center">
              <div className={`${Style.workspace_item} mt-2  w-100 ml-2`}>
                <div className={`ml-1 d-flex align-items-center pr-1 mb-1 mt-1 ${Style.list_sub_item_data} pb-1`}>
                  <span className="ml-1 fs-10px bold-500 w-100">We are syncing third party app. will update soon!</span>
                </div>
              </div>
            </div>
          )}

          {integrationStatusData.map((item, index) => {
            return (
              <div className="d-flex align-items-center" key={`integration-status-${index}`}>
                <Image src={item?.status ? SuccessTick : WarningIcon} width={"20px"} height={"20px"} />
                <div className={`${Style.workspace_item} mt-2  w-100 ml-2`} index={index}>
                  <span className={`${Style.list_sub_item} fs-10px ml-1`}>{item?.data?.name}</span>
                  <div className={`ml-1 d-flex align-items-center pr-1 mb-1 mt-1 ${Style.list_sub_item_data} pb-1`}>
                    <Image src={muralIcon} height={"20px"} />
                    <span className="ml-1 fs-10px bold-500 w-100">{item?.data?.msg}</span>
                    {item?.data?.copy_link && <CopyToClipBoardWithIcon textToBeCopied={item?.data?.msg} resetTime={3000} />}
                    {item?.data?.show_mural_configure && (
                      <Btn
                        className={`px-4 my-1 py-1 ${Style.list_sub_item_button}`}
                        onClick={() => {
                          navigate(`/app/companies/${LocalStorage.getData(COMPANY)}/courses/${state.data.course_id}/trainings/edit/${state.data.id}`)
                        }}
                      >
                        Configure
                      </Btn>
                    )}
                    {item?.data?.show_mural_authenticate && (
                      <Btn
                        className={`px-4 my-1 py-1 ${Style.list_sub_item_button}`}
                        onClick={() => {
                          disptachGlobalStore({ type: SET_APP_DATA, payload: { showModal: true } })
                        }}
                      >
                        Re Authenticate Account
                      </Btn>
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ExternalAppIntegrationStatus
