import React, { useContext, useEffect, useState } from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"

import { getRequest } from "../../../../services/rest_service"

import ShowMoreButton from "../show-more-button"
import ClassCard from "./class-card"
import NoData from "../no-data"
import { HasCreatePermission } from "../../../../utils/PermissionDataAccess"
import { UserSocketContext } from "../../../../stores/socket/user_socket/userSocketProvider"

// !definition of component
/**
 *
 * @param props --> companyID, trainingsLoader, trainings, setTrainings, setRefetchTrainings, trainingsNext, fetchNextTrainings, setTraining, setDeleteModalData, setToastData, handleShow and handleShowView
 * @description --> renders the classes data on dashboard
 * @returns ClassesData component for dashboard
 */
// ! component

const ClassesData = (props) => {
  const [userWSready, userWSval, userWSsend] = useContext(UserSocketContext)

  const [showMoreLoader, setShowMoreLoader] = useState(false)
  const fetchNextTrainings = async (next_url) => {
    setShowMoreLoader(true)
    const response = await getRequest(next_url)
    if (response.success) {
      if (response.data.results.length > 0) {
        props.setTrainings([...props.trainings, ...response.data.results])
      }
      props.setTrainingsNext(response.data.next)
      setShowMoreLoader(false)
    }
  }

  useEffect(() => {
    if (userWSval?.action === "third_party_app_sync") {
      var index = props.trainings.findIndex((res) => res.id === userWSval?.training)
      if (index > -1) {
        let tempTrainings = [...props.trainings]
        tempTrainings[index] = userWSval.data
        props.setTrainings(tempTrainings)
      }
    }
  }, [userWSval])

  return (
    <>
      {props.trainingsLoader === true ? (
        props.trainings ? (
          <React.Fragment>
            <div className={`d-flex flex-wrap pr-0 pl-0 mt-3`}>
              {props.trainings.map((datum, index) => (
                <ClassCard
                  key={index}
                  datum={datum}
                  companyID={props.companyID}
                  trainings={props.trainings}
                  setRefetchTrainings={props.setRefetchTrainings}
                  setTraining={props.setTraining}
                  setDeleteModalData={props.setDeleteModalData}
                  setToastData={props.setToastData}
                  handleShow={props.handleShow}
                  handleShowView={props.handleShowView}
                />
              ))}
              {HasCreatePermission("Classes") && props.trainings?.length <= 7 && (
                <NoData label="Create Class" onClick={() => navigate(`/app/companies/${props.companyID}/courses/new/trainings/create`)} />
              )}
            </div>
            <ShowMoreButton
              visible={props.trainingsNext !== null}
              loading={showMoreLoader}
              onClick={(e) => {
                e.preventDefault()
                fetchNextTrainings(props.trainingsNext)
              }}
            />
          </React.Fragment>
        ) : (
          <div className="text-center">No Classes available</div>
        )
      ) : (
        <p className="animated fadeIn pt-3 text-center">Loading...</p>
      )}
    </>
  )
}

ClassesData.propTypes = {
  companyID: PropTypes.any,
  trainingsLoader: PropTypes.bool.isRequired,
  trainings: PropTypes.array.isRequired,
  setTrainings: PropTypes.func.isRequired,
  setRefetchTrainings: PropTypes.func.isRequired,
  trainingsNext: PropTypes.any,
  setTrainingsNext: PropTypes.func.isRequired,
  setTraining: PropTypes.func.isRequired,
  setDeleteModalData: PropTypes.func.isRequired,
  setToastData: PropTypes.func.isRequired,
  handleShow: PropTypes.func.isRequired,
  handleShowView: PropTypes.func.isRequired,
}

export default ClassesData
