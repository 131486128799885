import React from "react"
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby"

import Card from "react-bootstrap/Card"
import { LazyLoadImage } from "react-lazy-load-image-component"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"

import { deleteRequest } from "../../../../../services/rest_service"
import Btn from "../../../../custom/Button"

import { ScholarHatLogo } from "../../../../../constants/global"

import edit from "./../../../../../images/svgs/edit.svg"
import deleteimg from "./../../../../../images/svgs/delete.svg"
import share from "./../../../../../images/share.png"
import arrowRight from "./../../../../../images/svgs/arrowdropright.svg"
import eye from "./../../../../../images/classroom/eye-icon.png"

import Styles from "./style.module.scss"
import { HasCreatePermission, HasDeletePermission, HasEditPermission, HasReadPermission } from "../../../../../utils/PermissionDataAccess"
import { CLASSES, COURSES, STUDENT } from "../../../../../constants/permissionsConstant"

// !definition of component
/**
 *
 * @param props --> datum, courses, companyID, setDeleteModalData, setToastData, handleShow and handleShowView
 * @description --> renders the course cards for dashboard
 * @returns course card components for dashboard
 */
// ! component

const CourseCard = (props) => {
  const deleteCourse = async (id) => {
    var index = props.courses.findIndex((res) => res.id === id)
    const response = await deleteRequest(`/courses/${id}/`)
    if (response.success) {
      let courses = [...props.courses]
      courses.splice(index, 1)
      props.setCourses(courses)
      props.setDeleteModalData({})
    } else {
      props.setToastData({ type: "error", title: response.msg })
    }
  }

  return (
    <Card className={`border_on_hover justify-content-between ${Styles.card}`}>
      <Card.Header className={`d-flex justify-content-between flex-grow-1 ${Styles.header}`}>
        <div>
          <LazyLoadImage className={`m-0`} src={props.datum.avatar ? props.datum.avatar : ScholarHatLogo} alt={props.datum.name} effect="blur" />
          <p className={`m-0 pt-9px turient-body-text-color turient-font-family-Medium ${Styles.heading}`}>{props.datum.name}</p>
        </div>
        <div className={`pl-1 d-flex flex-column justify-content-start`}>
          {HasEditPermission(COURSES) && (
            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Edit Course</Tooltip>}>
              <Link to={`/app/companies/${props.companyID}/courses/edit/${props.datum.id}`}>
                <Btn className={`bg_light_navy`} type={`round`}>
                  <img className={`mb-0 mx-auto ${Styles.button_image}`} alt="edit" src={edit} />
                </Btn>
              </Link>
            </OverlayTrigger>
          )}
          {!HasEditPermission(COURSES) && HasReadPermission(COURSES) && (
            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>View Course</Tooltip>}>
              <Link to={`/app/companies/${props.companyID}/courses/edit/${props.datum.id}`}>
                <Btn className={`bg_light_navy`} type={`round`}>
                  <img className={`mb-0 mx-auto ${Styles.button_image}`} alt="View" src={eye} />
                </Btn>
              </Link>
            </OverlayTrigger>
          )}

          {(props.datum.course_type === "1" && props.datum.elearning_training_detail?.participants < 1) ||
          (props.datum.course_type !== "1" && props.datum.training_count < 1) ? (
            HasDeletePermission(COURSES) && (
              <div className={`mt-2`}>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Delete Course</Tooltip>}>
                  <Btn
                    className={`bg_light_navy`}
                    type={`round`}
                    onClick={() => {
                      props.setDeleteModalData({
                        itemName: props.datum?.name,
                        action: () => {
                          deleteCourse(props.datum?.id)
                        },
                      })
                    }}
                  >
                    <img className={`mb-0 mx-auto ${Styles.button_image}`} alt="delete course" src={deleteimg} />
                  </Btn>
                </OverlayTrigger>
              </div>
            )
          ) : (
            <></>
          )}
          {props.datum.course_type === "1" && props.datum.elearning_training_detail !== null ? (
            <div className={`mt-2`}>
              <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Share eLearning Course</Tooltip>}>
                <Btn
                  className={`bg_light_navy`}
                  type={`round`}
                  onClick={(e) => {
                    props.setShowSharedArtifact(true)
                    props.setSharedArtifactProps({
                      trainingId: props.datum.elearning_training_detail?.id,
                    })
                  }}
                >
                  <img className={`mb-0 mx-auto ${Styles.button_image}`} alt="share course" src={share} />
                </Btn>
              </OverlayTrigger>
            </div>
          ) : (
            <></>
          )}
        </div>
      </Card.Header>
      <Card.Footer className={`d-flex justify-content-between align-items-center ${Styles.footer}`}>
        {((props.datum.course_type === "1" && HasReadPermission(STUDENT)) || (HasReadPermission(CLASSES) && props.datum.course_type !== "1")) && (
          <p
            className={`mb-0 turient-body-text-color pointer`}
            onClick={() =>
              props.datum.course_type === "1"
                ? props.handleShowView(props.datum.elearning_training_detail)
                : navigate(`/app/companies/${props.companyID}/courses/view/` + props.datum.id)
            }
          >
            {props.datum.elearning_training_detail?.participants} {props.datum.course_type === "1" ? "Students" : "View Classes"}
          </p>
        )}
        {((props.datum.course_type === "1" && HasCreatePermission(STUDENT)) || (HasCreatePermission(CLASSES) && props.datum.course_type !== "1")) && (
          <Btn
            className={`px-3 py-2 bg_light_navy d-flex`}
            type="small"
            onClick={() =>
              props.datum.course_type === "1"
                ? props.handleShow(props.datum, "course")
                : navigate(`/app/companies/${props.companyID}/courses/${props.datum.course_template.id}/trainings/create`)
            }
          >
            <span className={`mr-1`}>{props.datum.course_type === "1" ? "Add Students" : "Add Classes"}</span>
            <img className={`circle m-0 ${Styles.footer_button_icon}`} alt="delete course" src={arrowRight} />
          </Btn>
        )}
      </Card.Footer>
    </Card>
  )
}

CourseCard.propTypes = {
  datum: PropTypes.object.isRequired,
  courses: PropTypes.array.isRequired,
  setCourses: PropTypes.func.isRequired,
  companyID: PropTypes.any,
  setDeleteModalData: PropTypes.func.isRequired,
  setToastData: PropTypes.func.isRequired,
  setShowSharedArtifact: PropTypes.func.isRequired,
  setSharedArtifactProps: PropTypes.func.isRequired,
  handleShow: PropTypes.func.isRequired,
  handleShowView: PropTypes.func.isRequired,
}

export default CourseCard
