import React, { createContext, useReducer } from "react"
import IntegrationStatusReducer from "./IntegrationStatusReducer"

const initalState = {
  status: false,
  data: {},
}

const IntegrationStatusStore = ({ children }) => {
  const [state, dispatch] = useReducer(IntegrationStatusReducer, initalState)

  return <IntegrationStatusContext.Provider value={[state, dispatch]}>{children}</IntegrationStatusContext.Provider>
}

export const IntegrationStatusContext = createContext()
export default IntegrationStatusStore
