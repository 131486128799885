// import ...
import React, { Component } from "react"
import { navigate } from "gatsby"

import { Auth } from "../services/auth"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const auth = new Auth()
  if (!auth.isAuthenticated() && location.pathname !== `/app/login`) {
    navigate("/app/login")
    return null
  }
  return <Component {...rest} />
}
export default PrivateRoute
