import { TRAINING_INTEGRATION_STATUS_MODAL, RESET_TRAINING_INTEGRATION_STATUS_MODAL } from "./IntegrationStatusActions"

const IntegrationStatusReducer = (state, action) => {
  switch (action.type) {
    case TRAINING_INTEGRATION_STATUS_MODAL:
      return {
        ...state,
        data: action.payload,
        status: true,
      }
    case RESET_TRAINING_INTEGRATION_STATUS_MODAL:
      return {
        ...state,
        data: {},
        status: false,
      }
    default:
      return state
  }
}

export default IntegrationStatusReducer
