import React, { useContext, useEffect, useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import Card from "react-bootstrap/Card"
import { LazyLoadImage } from "react-lazy-load-image-component"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"

import utcDateTime from "../../../../../services/utcdatetime"
import { deleteRequest } from "../../../../../services/rest_service"

import Btn from "../../../../custom/Button"
import eye from "./../../../../../images/classroom/eye-icon.png"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendar } from "@fortawesome/free-solid-svg-icons"

import edit from "./../../../../../images/svgs/edit.svg"
import deleteimg from "./../../../../../images/svgs/delete.svg"
import desktop from "./../../../../../images/svgs/desktop.svg"
import arrowRight from "./../../../../../images/svgs/arrowdropright.svg"
import { ScholarHatLogo } from "../../../../../constants/global"
import muralIcon from "./../../../../../images/svgs/mural-icon.svg"

import LessonBoardInsightWrapper from "../../../../insights/LessonBoardInsight"

import Styles from "./style.module.scss"
import { HasCreatePermission, HasDeletePermission, HasEditPermission, HasReadPermission } from "../../../../../utils/PermissionDataAccess"
import { CLASSES, STUDENT } from "../../../../../constants/permissionsConstant"
import { IntegrationStatusContext } from "../../../../../stores/integration_status_store/IntegrationStatusStore"
import { TRAINING_INTEGRATION_STATUS_MODAL } from "../../../../../stores/integration_status_store/IntegrationStatusActions"
import { GlobalContext } from "../../../../../stores/global_store/GlobalStore"

// !definition of component
/**
 *
 * @param props --> companyID, trainings, setRefetchTrainings, setTraining, setDeleteModalData, setToastData, handleShow and handleShowView
 * @description --> renders the class cards for dashboard
 * @returns ClassCard component for dashboard
 */
// ! component

const ClassCard = (props) => {
  const [state, dispatch] = useContext(IntegrationStatusContext)
  const [globalState, globalStateDispatch] = useContext(GlobalContext)
  const [classStatus, setClassStatus] = useState("")
  const isMuralEnabled = globalState.appData.data?.company?.mural_enabled

  useEffect(() => {
    if (props.datum) {
      getClassStatus()
    }
  }, [props.datum])

  const deleteTraining = async (id) => {
    var index = props.trainings.findIndex((res) => res.id === id)
    const response = await deleteRequest(`/trainings/${id}/`)
    if (response.success) {
      let trainings = [...props.trainings]
      trainings.splice(index, 1)
      props.setRefetchTrainings(true)
      props.setTraining([])
      props.setDeleteModalData({})
    } else {
      props.setToastData({ type: "error", title: response.msg })
    }
  }

  const getClassStatus = () => {
    let currentDate = new Date()
    let startDate = new Date(props.datum.start_date)
    let endDate = new Date(props.datum.end_date)
    if (currentDate < startDate) {
      setClassStatus("Upcoming")
    } else if (currentDate >= startDate && currentDate <= endDate) {
      setClassStatus("Ongoing")
    } else if (currentDate > endDate) {
      setClassStatus("Completed")
    }
  }

  return (
    <Card
      className={`border_on_hover justify-content-between ${Styles.card} ${classStatus === "Upcoming" && Styles.upcoming_class} ${
        classStatus === "Ongoing" && Styles.ongoing_class
      } ${classStatus === "Completed" && Styles.completed_class}`}
    >
      <Card.Header className={`d-flex justify-content-between flex-grow-1 ${Styles.header}`}>
        <div>
          <LazyLoadImage className={`m-0`} src={props.datum.avatar ? props.datum.avatar : ScholarHatLogo} alt={props.datum.name} effect="blur" />
          <p className={`m-0 turient-body-text-color turient-font-family-Medium ${Styles.heading}`}>
            <OverlayTrigger
              placement={"bottom"}
              overlay={
                <Tooltip className="event-tooltip fs-12px w-23rem" id={`tooltip-${props.datum.id}`}>
                  {props.datum.name}
                </Tooltip>
              }
            >
              <span>{props.datum.name}</span>
            </OverlayTrigger>
          </p>
          <div className={`col-12 px-0 mb-0 turient-body-text-color`}>
            <FontAwesomeIcon icon={faCalendar} className={`mt-1 float-left ${Styles.calendar_icon}`} />
            <div className={`ml-3 pl-3 mb-0 turient-font-family-Medium ${Styles.date_font}`}>
              {utcDateTime.displayTrainingDate(props.datum.start_date, props.datum.end_date)}
            </div>
            <p className={`ml-3 pl-3 turient-font-family-Medium mb-0 ${Styles.date_font}`}>
              {utcDateTime.displayTimeDetail(props.datum.start_date, props.datum.end_date)}
            </p>
          </div>
        </div>
        <div className={`pl-1 d-flex flex-column justify-content-center`}>
          {process.env.GATSBY_REACT_APP_ENABLE_INSIGHTS === "true" && (
            <div className={`align-self-center`}>
              <LessonBoardInsightWrapper type={`dashboard`} training_id={props.datum.id} template_id={props.datum.training_activity_template_id} />
            </div>
          )}
          {HasEditPermission("Classes") && (
            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Edit Class</Tooltip>}>
              <Link to={`/app/companies/${props.companyID}/courses/${props.datum.course_id}/trainings/edit/` + props.datum.id}>
                <Btn className={`bg_light_navy`} type={`round`}>
                  <img className={`mb-0 mx-auto ${Styles.button_image}`} alt="edit" src={edit} />
                </Btn>
              </Link>
            </OverlayTrigger>
          )}
          {!HasEditPermission(CLASSES) && HasReadPermission(CLASSES) && (
            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>View Class</Tooltip>}>
              <Link to={`/app/companies/${props.companyID}/courses/${props.datum.course_id}/trainings/edit/` + props.datum.id}>
                {" "}
                <Btn className={`bg_light_navy`} type={`round`}>
                  <img className={`mb-0 mx-auto ${Styles.button_image}`} alt="View" src={eye} />
                </Btn>
              </Link>
            </OverlayTrigger>
          )}
          <div className="my-2">
            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Class Dashboard</Tooltip>}>
              <Link to={`/main/class-dashboard/${props.datum.training_activity_template_id}/${props.datum.id}/`}>
                <Btn className={`bg_light_navy`} type={`round`}>
                  <img className={`mb-0 mx-auto ${Styles.button_image}`} alt="classroom dashboard" src={desktop} />
                </Btn>
              </Link>
            </OverlayTrigger>
          </div>
          {HasDeletePermission("Classes") && (
            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Delete Class</Tooltip>}>
              <div>
                <Btn
                  className={`bg_light_navy`}
                  type={`round`}
                  onClick={() => {
                    props.setDeleteModalData({
                      itemName: props.datum?.name,
                      action: () => {
                        deleteTraining(props.datum?.id)
                      },
                    })
                  }}
                >
                  <img className={`mb-0 mx-auto ${Styles.button_image}`} alt="delete class" src={deleteimg} />
                </Btn>
              </div>
            </OverlayTrigger>
          )}
        </div>
      </Card.Header>
      <Card.Footer className={`d-flex align-items-center justify-content-between ${Styles.footer}`}>
        {HasReadPermission(STUDENT) && (
          <p className={`mb-0 turient-body-text-color pointer`} onClick={() => props.handleShowView(props.datum)}>
            {props.datum.participants} Students
          </p>
        )}
        {utcDateTime.utcDateTime("today") - utcDateTime.utcDateTime(props.datum.end_date) < 0 && HasCreatePermission(STUDENT) && (
          <Btn type="small" className={`px-3 py-2 bg_light_navy d-flex`} onClick={() => props.handleShow(props.datum)}>
            <span className={`mr-1`}>Add Students</span>
            <img className={`circle m-0 ${Styles.footer_button_icon}`} alt="delete course" src={arrowRight} />
          </Btn>
        )}
      </Card.Footer>
      {isMuralEnabled ? (
        props.datum?.third_party_process_count?.failed_count > 0 ? (
          <div
            className="d-flex"
            onClick={() => {
              dispatch({ type: TRAINING_INTEGRATION_STATUS_MODAL, payload: props.datum })
            }}
          >
            <span className={`bg-AA5746 fs-11px w-100 text-center underline pt-1 pb-1 pointer ${Styles.third_party_app_notification}`}>
              Action required : Third Party App failed
            </span>
          </div>
        ) : props.datum?.third_party_process_count?.total_count > 0 ? (
          <div
            className="d-flex"
            onClick={() => {
              dispatch({ type: TRAINING_INTEGRATION_STATUS_MODAL, payload: props.datum })
            }}
          >
            <span className={`bg-197C7C fs-12px w-100 text-center pt-1 pb-1 pointer underline ${Styles.third_party_app_notification}`}>
              <LazyLoadImage src={muralIcon} height={"12px"} /> view
            </span>
          </div>
        ) : (
          <></>
          // <div className="d-flex">
          //   <span className={`bg-586886 fs-12px w-100 text-center pt-1 pb-1 disable-content ${Styles.third_party_app_notification}`}>
          //     Third Party App not configured
          //   </span>
          // </div>
        )
      ) : (
        ""
      )}
    </Card>
  )
}

ClassCard.propTypes = {
  datum: PropTypes.object.isRequired,
  companyID: PropTypes.any,
  trainings: PropTypes.array.isRequired,
  setRefetchTrainings: PropTypes.func.isRequired,
  setTraining: PropTypes.func.isRequired,
  setDeleteModalData: PropTypes.func.isRequired,
  setToastData: PropTypes.func.isRequired,
  handleShow: PropTypes.func.isRequired,
  handleShowView: PropTypes.func.isRequired,
}

export default ClassCard
