import React from "react"
import PropTypes from "prop-types"

import Btn from "../../../custom/Button"

// !definition of component
/**
 *
 * @param props --> visible and onClick
 * @description --> visible if more data is available and accepts a function prop meant to load more data
 * @returns ShowMoreButton component
 */
// ! component

const ShowMoreButton = (props) => {
  return (
    <div className={`${props.visible ? `d-flex` : `d-none`} mb-3 justify-content-left ml-3`}>
      <Btn className={`bg_light_navy`} type="small" onClick={props.onClick} disabled={props.loading}>
        {props.loading ? <span>Loading...</span> : <span>Show More</span>}
      </Btn>
    </div>
  )
}

ShowMoreButton.propTypes = {
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

ShowMoreButton.defaultProps = {
  visible: true,
  loading: false,
}

export default ShowMoreButton
