import React from "react"
import PropTypes from "prop-types"
import Image from "../custom/Image"
import error from "../../images/svgs/error.svg"

// !definition of component
/**
 *
 * @param props --> errorText
 * @description --> Warns users against an error.
 * @returns Login form error message component
 */
// ! component

const LoginFormError = (props) => {
  return (
    <div className={`d-flex pt-1 text-ED2F2F fs-12px`}>
      <Image className={`d-inline`} src={error} height={15} width={15} alt="error" unoptimized={true} />
      <span className="ml-1 color-AA5746"> {props.errorText}</span>
    </div>
  )
}

LoginFormError.propTypes = {
  errorText: PropTypes.string.isRequired,
}

export default LoginFormError
