import React from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"

import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import FormGroup from "react-bootstrap/FormGroup"
import Dropdown from "react-bootstrap/Dropdown"
import Col from "react-bootstrap/Col"

import { putRequest } from "../../../services/rest_service"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRss, faChevronRight } from "@fortawesome/free-solid-svg-icons"

import Styles from "./style.module.scss"

// !definition of component
/**
 *
 * @param props --> showView, setShowView and participants
 *                  upcoming feature props --> {stages, training, fetchStudents and setToastData}
 * @description --> Shows the list of particpants added
 * @returns ParticipantsDataModel component
 */
// ! component

const ParticipantsDataModel = (props) => {
  // const selectItem = async (e, stage, participant) => {
  //   e.preventDefault()
  //   var formData = new FormData()
  //   formData.append("stage", stage.id)
  //   if (stage && stage !== undefined) {
  //     const response = await putRequest(`/training-tracks/${participant.id}/edit/`, formData)
  //     if (response.success) {
  //       props.setToastData({
  //         type: "success",
  //         title: "Successfully changed the status",
  //       })
  //       props.fetchStudents()
  //     } else {
  //       props.setToastData({ type: "error", title: "Something went wrong" })
  //     }
  //   }
  // }
  return (
    <Modal show={props.showView} onHide={props.setShowView} centered dialogClassName="participants-dialog">
      <Modal.Header className="participant-modal align-items-baseline bg_dark_navy" closeButton>
        <div className={`d-flex w-100`}>
          <div className={`align-self-center`}>
            <p className={`m-0 font-weight-bold text_light`}>Participants</p>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className={`p-0 bg_dark_navy ${Styles.modal_body}`}>
        {props.participants && props.participants.length > 0 ? (
          props.participants.map((participant, index2) => (
            <div className={`d-flex mb-1 py-2 bg_pale_navy`} key={index2 + `_training`}>
              <div className={`pl-3 align-self-center`}>
                <FontAwesomeIcon icon={faRss} className="text_light_orange" size="lg" />
              </div>
              <div className={`pl-3 align-self-center`}>
                <p className={`m-0 font-weight-bold text-white`}>{participant.owner__email}</p>
              </div>
              {/* <div className={`pl-3 ml-auto mr-3 align-self-center ${`${Styles.choose_stage}`}`}>
                <FormGroup className={`col-10`}>
                  <label className={`font-weight-bold text-white`} htmlFor="course_template">
                    Choose Stage
                  </label>
                  <Dropdown>
                    <Dropdown.Toggle variant="info" id="dropdown-basic">
                      {participant.stage__name !== null ? participant.stage__name : "Select Stage"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {props.stages.map((stage, index) => (
                        <Dropdown.Item key={index.toString() + "Template"} name="course_template" onClick={(e) => selectItem(e, stage, participant)}>
                          {stage.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </FormGroup>
              </div>
              <div className="d-flex align-items-center p-2">
                <Button
                  className={`text-white ${Styles.class_report}`}
                  onClick={() => {
                    navigate("/app/students/" + participant.owner_id + "/training/" + props.training.course_id)
                  }}
                >
                  View Class Report <FontAwesomeIcon icon={faChevronRight} size="sm" />
                </Button>
              </div> */}
            </div>
          ))
        ) : (
          <Col>
            <p className="text-white p-2">No Participants Added</p>
          </Col>
        )}
      </Modal.Body>
    </Modal>
  )
}

ParticipantsDataModel.propTypes = {
  showView: PropTypes.bool.isRequired,
  setShowView: PropTypes.func.isRequired,
  participants: PropTypes.array.isRequired,
  // stages: PropTypes.array.isRequired,
  // training: PropTypes.array.isRequired,
  // fetchStudents: PropTypes.func.isRequired,
  // setToastData: PropTypes.func.isRequired,
}

export default ParticipantsDataModel
