import React, { useState, useContext } from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"

import Modal from "react-bootstrap/Modal"
import Col from "react-bootstrap/Col"

import { SidebarContext } from "../../../../stores/sidebar_store/SidebarStore"
import { SET_OPEN_UPLOAD_MODAL } from "../../../../stores/sidebar_store/SidebarActions"

import AddDataCard from "./add-data-card"
import ClassRoomPoll from "../../../class-room-training/classroom-poll"

import Styles from "./style.module.scss"
import { HasCreatePermission } from "../../../../utils/PermissionDataAccess"
import { CLASSES, COURSES } from "../../../../constants/permissionsConstant"

// !definition of component
/**
 *
 * @param props --> showAddDataModal, setShowAddDataModal, companyID, coursesExist and setToastData
 * @description --> Allows users to add courses, classes, resources and polls
 * @returns Add Data Modal component
 */
// ! component

const AddDataModal = (props) => {
  const [sidebarState, dispatch] = useContext(SidebarContext)

  const [createNewPoll, setCreateNewPoll] = useState(false)
  const [showPollModal, setShowPollModal] = useState(false)

  const addDataCards = [
    {
      heading: "Courses",
      context:
        "Creating courses has never been this easy. Predefined templates, simple workflows, and efficient resource management help you stay ahead of the game! Get started now!",
      buttonText: "Create a New Course",
      onClick: () => {
        navigate(`/app/companies/${props.companyID}/courses/course-information`)
      },
    },
    {
      heading: "Classes",
      context:
        "Setup and conduct your live online classes with fun-filled, exciting and super engaging features. You can setup your class and schedule in just 4 easy steps.",
      buttonText: "Create a New Class",
      onClick: () => {
        if (props.coursesExist === true) {
          navigate(`/app/companies/${props.companyID}/courses/new/trainings/create`)
        } else {
          props.setToastData({
            type: "error",
            title: "You need atleast 1 course to proceed",
          })
        }
      },
    },
    {
      heading: "Resources",
      context: "Help your learners access informative resources through your online classroom. Upload videos, articles, documents, etc.",
      buttonText: "Upload Resources",
      onClick: () => {
        dispatch({ type: SET_OPEN_UPLOAD_MODAL, payload: true })
        props.setShowAddDataModal(false)
      },
    },
    {
      heading: "Polls",
      context:
        "Interact with your learners by engaging them in exciting polls. Create & customize polls anyway you want and find out what your learners are thinking.",
      buttonText: "Create Polls",
      onClick: () => {
        setCreateNewPoll(true)
        setShowPollModal(true)
        props.setShowAddDataModal(false)
      },
    },
  ]
  const cardListLength = addDataCards.filter((datum) => {
    if (HasCreatePermission(datum.heading) && [COURSES, CLASSES].includes(datum.heading)) {
      return datum
    }
  }).length
  return (
    <>
      <Modal size="xl" centered show={props.showAddDataModal} onHide={props.setShowAddDataModal}>
        <Modal.Header className={`${Styles.modal_header}`}>
          <div>
            <p>Create Anything</p>
          </div>
        </Modal.Header>
        <Modal.Body className={`px-2 ${Styles.modal_body}`}>
          <Col lg="12" md="12" sm="12" xs="12" className="float-left px-0 py-0">
            {addDataCards.map((datum, index) =>
              ![COURSES, CLASSES].includes(datum.heading) ? (
                <AddDataCard
                  key={index}
                  heading={datum.heading}
                  context={datum.context}
                  buttonText={datum.buttonText}
                  onClick={datum.onClick}
                  size={cardListLength + 2}
                />
              ) : (
                HasCreatePermission(datum.heading) &&
                [COURSES, CLASSES].includes(datum.heading) && (
                  <AddDataCard
                    key={index}
                    heading={datum.heading}
                    context={datum.context}
                    buttonText={datum.buttonText}
                    onClick={datum.onClick}
                    size={cardListLength + 2}
                  />
                )
              )
            )}
          </Col>
        </Modal.Body>
      </Modal>
      <ClassRoomPoll
        path="dashboard"
        create_new_poll={createNewPoll}
        reset_create_poll={() => setCreateNewPoll(false)}
        live_classroom={false}
        poll={""}
        showPollResult={false}
        show={showPollModal}
        closeModal={() => setShowPollModal(false)}
        fetchAllResources={(arg) => {}}
      />
    </>
  )
}

AddDataModal.propTypes = {
  showAddDataModal: PropTypes.bool.isRequired,
  setShowAddDataModal: PropTypes.func.isRequired,
  companyID: PropTypes.any,
  coursesExist: PropTypes.bool.isRequired,
  setToastData: PropTypes.func.isRequired,
}

export default AddDataModal
