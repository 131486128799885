import React from "react"
import PropTypes from "prop-types"

import Styles from "./style.module.scss"

// !definition of component
/**
 *
 * @param props --> heading and sections
 * @description --> sections array should include a map of objects having a section name string, its respective onClick function and active state boolean
 * @returns Navbar component for dashboard
 */
// ! component

const Navbar = (props) => {
  return (
    <nav className={Styles.nav}>
      <h1 className={`${Styles.nav_heading}`}>{props.heading}</h1>
      <ul className={`${Styles.nav_ul}`}>
        {props.sections.map((obj, index) => {
          return (
            <li key={index} className={`${Styles.nav_li} ${obj.active ? Styles.active_nav_li : ""}`} onClick={obj.onClick}>
              {obj.name}
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

Navbar.propTypes = {
  heading: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string.isRequired, onClick: PropTypes.func.isRequired, active: PropTypes.bool.isRequired }).isRequired
  ).isRequired,
}

export default Navbar
